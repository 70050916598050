import React from "react"

import Layout from "../../layouts/En"

import SEO from "../../components/SEO"
import CheckPoint from "../../components/Checkpoint"

const SoftwareDevelopmentPage = () => (
  <Layout title="Software Development">
    <SEO keywords={[`software`, `development`]} title="Software Development" />

    <section className="flex justify-center mb-12">
      <div className="w-full sm:w-3/4 md:w-2/3">
        <p className="mb-8">
          We have done software for offer calculations, cost tracking, and
          technical equipment selection, among other things. Our special
          expertise is Web and Windows applications, e.g. Joomla publishing
          systems custom components.
        </p>
        <h3 className="mb-4">Customer-specific implementations:</h3>
        <CheckPoint className="mb-8">
          Car trade offer calculation program
        </CheckPoint>
        <CheckPoint className="mb-8">
          Technical implementation of XXL events fan photos
        </CheckPoint>
        <CheckPoint className="mb-8">
          Kair selection program for HVAC units (
          <a className="text-orange" href="https://www.kair.fi/suunnittelija">
            www.kair.fi/suunnittelija
          </a>
          )
        </CheckPoint>
        <CheckPoint className="mb-8">
          Data transfer from Lemonsoft ERP to Monitor ERP
        </CheckPoint>
        <CheckPoint className="mb-8">
          Forwarding Siemens automation system alarms to different messaging
          platforms
        </CheckPoint>
        <CheckPoint>Creating and editing DWG files machinically</CheckPoint>
      </div>
    </section>

    <section className="flex justify-center mb-12">
      <div className="w-full sm:w-3/4 md:w-2/3">
        <p className="mb-8">
          We do versatile software integrations with strong professional skills.
          Decades of experience and continuous training enable us to make quick
          solutions. We have become familiar with the ways in which different
          systems handle databases, as well as the operating methods and
          limitations of older systems. In projects, we can also utilize our
          financial management expertise.
        </p>
        <CheckPoint className="mb-8">Monitor ERP</CheckPoint>
        <CheckPoint className="mb-8">
          EmCe financial management and accounting system
        </CheckPoint>
        <CheckPoint className="mb-8">
          Passel cash and inventory accounting
        </CheckPoint>
        <CheckPoint className="mb-8">Lemonsoft ERP</CheckPoint>
        <CheckPoint className="mb-8">Skalex landfill software</CheckPoint>
        <CheckPoint className="mb-8">Siemens SIMATIC S7</CheckPoint>
        <CheckPoint className="mb-8">Siemens WinCC</CheckPoint>
        <CheckPoint className="mb-8">InstaWahti</CheckPoint>
        <CheckPoint>Kantech EntraPass</CheckPoint>
      </div>
    </section>
  </Layout>
)

export default SoftwareDevelopmentPage
